@media (min-width: 1201px) {
  body {
    &.mmenu-opened {
      &:after {
        display: none;
      }
    }
  }
}

@media (max-width: 1200px) {
  body {
    .mmenu-wrapper {
      display: flex;
      justify-content: left;
    }
  }
}